import { StyleSheet } from 'react-native';


export const globalColors = {
  primary: '#75C10B',
  primaryLight: '#E5F3C5',
  primaryExtraLight: '#EAF7D5',
  primaryDark: '#4E8A00',
  secondary: '#528708',
  accent: '#0096CA',
  accentLight: '#D6F0FF',
  accentDark: '#195B70',
  grey: '#C4C6C1',
  text: '#5D5F59',
  textSecondary: '#897B78',
  background: '#F2F5F6',
  positive: '#70C270',
  positiveLight: '#E3F3E3',
  negative: '#F87E77',
  negativeLight: '#FDDBD8'
};

export const globalStyle = StyleSheet.create({
  landingContainer: {
    width: '100%',
    height: '100%',
    overflow: 'scroll',
    alignItems: 'center',
  },
  landingContainerCentral: {
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: 450,
    marginTop: 70,
    paddingTop: 10,
    paddingBottom: 40,
    paddingHorizontal: 32,
    textAlign: 'center',
    alignItems: 'center',
  },
  screenHeaderContainer: {
    display: 'flex', 
    alignItems: 'center',
    marginBottom: 20,
  },
  screenHeaderText: {
    fontSize: 26,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'center',
    color: globalColors.accent
  },
  dividerLine: {
    width: '100%',
    height: 1,
    backgroundColor: globalColors.grey,
    marginVertical: 20,
  },
  screenSubHeaderText: {
    fontSize: 18,
    marginTop: 4
  },
  sectionHeaderContainer: {
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 20,
    width: '100%',
    borderBottomColor:globalColors.grey,
    borderBottomWidth: 1,
  },
  sectionHeaderText: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10
  },
  fieldRow: {
    flexDirection: "row",
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 24
  },
  fieldRowCentered: {
    display: 'flex', 
    alignItems: 'center',
    marginTop: 20
  },
  fieldNameStyle: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'left',
    marginBottom: 3,
    flexWrap: 'wrap'
  }
});

