import { StyleSheet, Button, TouchableOpacity, View  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import DropdownField from '../../components/DropdownFieldFormik';
import axiosInstance from '../../axiosInstance';
import ContentContainer from '../../components/ContentContainer';
import ContentHeader from '../../components/ContentHeader';
import {globalStyle} from '../../style';
import {residencyStates} from '../../assets/constants';
import { replaceEmptyWithNull, replaceNullWithEmpty } from '../../utils/index'; 
import { handleFilingIdParam, navigateFiling } from '../../utils';

export default function ResidencyScreen({navigation, route}) {
  // declare field state variables
  const initialValues = {residencyState2: ''}
  const [ formValues, setFormValues ] = useState(initialValues);
  const [ filingId, setFilingId ] = useState('');

  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    axiosInstance
      .post('loadResidencyScreen', JSON.stringify({'filingId': newFilingId}), { headers: {'Content-Type': 'application/json'}, withCredentials: true})
      .then(function (response) {
        setFormValues(replaceNullWithEmpty(response['data']));
      })
      .catch(function (error) {console.log(error)})
  }, [])

  const continuePressed = (formValues, setSubmitting, setValues) => {
    let cleanFormValues = replaceEmptyWithNull(structuredClone(formValues));
    cleanFormValues['filingId'] = filingId;
    axiosInstance
      .post('updateResidencyInfo', cleanFormValues, { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
          setSubmitting(false);
          navigateFiling(navigation, response.data.next_screen, { filingId: filingId });
        })
      .catch(function (error) {console.log(error.message);})
  };
  const backPressed = () => {
    navigateFiling(navigation, 'contactInfo', { filingId: filingId });
  };

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
    <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed}>
      <ContentHeader icon={"home"} headerText="Let's establish your residency status" subHeaderText="This will help us figure out which state filings you will need to make"/>
      
      <View style={globalStyle.fieldRow}>
        <DropdownField name="residencyState2" fieldName="What other states did you live in during 2021?" placeholder="Choose" items={residencyStates} width={100} />
      </View>
    </ContentContainer>
    )}
    </Formik>
  );
}