import { View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../components/ContentContainer';
import ContentHeader from '../../components/ContentHeader';
import BooleanField from '../../components/BooleanFieldFormik';
import DropdownField from '../../components/DropdownFieldFormik';
import axiosInstance from '../../axiosInstance';
import {globalStyle} from '../../style';
import { replaceNullWithEmpty, replaceEmptyWithNull, updateWithDictOverlap, navigateFiling } from '../../utils/index';
import { handleFilingIdParam } from '../../utils';
import { monthCount } from '../../assets/constants';

export default function QualifiedRelativeScreen({route, navigation}) {
  const initialValues = {
    'firstName': '',
    'sharedResidencyBool': '', 
    'sharedResidencyMonths': '',
  }
  const [ formValues, setFormValues ] = useState(initialValues);
  const [ filingId, setFilingId ] = useState('');
  const isFocused = useIsFocused();

  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    let dependentID = route.params.dependentID;
    if (isFocused && dependentID !== null) {
      pullDependent(newFilingId, dependentID);
    }
  }, [isFocused]);

  const pullDependent = (newFilingId, dependentID) => {
    setFormValues(initialValues);
    var jsonData = JSON.stringify({'relationshipId': dependentID, 'filingId': newFilingId});
    axiosInstance
      .post('loadEditDependentScreen', jsonData, { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        let newVals = replaceNullWithEmpty(response['data']);
        setFormValues(prevValues => updateWithDictOverlap(prevValues, newVals));
      })
      .catch(function (error) {console.log(error);});
  };

  const sharedResidencyMonthsQ = (sharedResidencyBool, firstName) => {
    return (sharedResidencyBool=== true) ? 
    <View style={globalStyle.fieldRow}>
      <DropdownField name="sharedResidencyMonths" fieldName={`How many months did ${firstName} live with you?`} placeholder="Choose" items={monthCount} width={200} /> 
    </View>: 
      <View></View>;
  };

  const continuePressed = (formValues, setSubmitting, setValues) => {
    const dependentID = route.params.dependentID;
    let cleanFormValues = replaceEmptyWithNull(structuredClone(formValues), ['sharedResidencyBool', 'sharedResidencyMonths']);
    cleanFormValues['id'] = dependentID;
    cleanFormValues['filingId'] = filingId;

    axiosInstance
      .post('qualifiedDependentCheck', JSON.stringify(cleanFormValues), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
          setSubmitting(false);
          navigateFiling(navigation, 'qualifiedDependentResult', 
          {'firstName': formValues['firstName'], 
          'qualifiedBool': response['data']['qualifiedBool'],
          'dependentID': route.params.dependentID,
          'relationship': 'qualifiedRelative',
          'filingId': filingId
        });
      })
      .catch(function (error) {console.log(error.message);
      })
  };
  const backPressed = () => {
    navigateFiling(navigation, 'addDependent', {'filingId': filingId, 'dependentID': route.params.dependentID});
  };

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
    <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed}>
      <ContentHeader icon={"family-restroom"} headerText="Share details on your dependent"/>
  
      <View style={globalStyle.fieldRow}>
        <BooleanField name="sharedResidencyBool" fieldName={`Did ${values['firstName']} live with you for more than half of the year?`} />
      </View>

      {sharedResidencyMonthsQ(values['sharedResidencyBool'], values['firstName'])}
    </ContentContainer>
     )}
     </Formik>
  );
}