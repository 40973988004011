import React, { useState, useEffect } from 'react';
import { Button, TouchableOpacity, View, StyleSheet } from 'react-native';
import {globalColors, globalStyle} from '../../style';
import axiosInstance from '../../axiosInstance';
import StyledText from '../../components/StyledText';
import { MaterialIcons } from '@expo/vector-icons';
import { logOut, navigateFiling } from '../../utils';

 export default function FilingScreen({navigation}) {
  const [ filings, setFilings ] = useState([])

  useEffect(() => {
    axiosInstance
      .get('loadFilingsScreen', {withCredentials: true})
      .then(function (response) {
        setFilings(response['data']);
      })
      .catch(function (error) {
      });
  }, []);

  return (

      <View style={{...globalStyle.landingContainer, backgroundColor: 'white'}}>
        <View style={globalStyle.landingContainerCentral}>
          <StyledText style={{paddingTop: 16, paddingBottom: 16, fontSize: 28, fontWeight: 'bold'}}>Select a filing</StyledText>

          {filings.map((filing, index) => (
            <TouchableOpacity 
              key={index} 
              style={[styles.filingContainer, { backgroundColor: filing.status === 'Complete' ? globalColors.grey : globalColors.primary } ]} 
              onPress={() => navigateFiling(navigation, 'personalInfo', { filingId: filing.filingId }) }
              >
              <View style={{flexDirection: 'row'}}>
                <StyledText style={{color: globalColors.background, marginRight: 5, fontWeight: 'bold'}}>{filing.year}</StyledText>
                <StyledText style={{color: globalColors.background, fontWeight: 'bold'}}>{filing.status}</StyledText>
              </View>
            </TouchableOpacity>
          ))}
            <TouchableOpacity style={styles.filingContainer} onPress={()=>navigation.navigate('income', { filingId: null }) }>
              <View style={{flexDirection: 'row'}}>
                <MaterialIcons name={"add"} size={20} color={ globalColors.background } />
                <StyledText style={{color: globalColors.background, marginRight: 5, fontWeight: 'bold'}}>  Start a new filing</StyledText>
              </View>
            </TouchableOpacity>
        </View>

        <View style={styles.signOutContainer}>
            <TouchableOpacity onPress={() => logOut(navigation)} style={{flexDirection: 'row'}}>
                <MaterialIcons name={"logout"} size={20} color={ globalColors.text } />
                <StyledText style={{color: globalColors.text, paddingLeft: 10, fontWeight: 'bold'}}>Sign Out</StyledText>
            </TouchableOpacity>
        </View>        
      </View>

  )
 };

 const styles = StyleSheet.create({
  filingContainer: {
    backgroundColor: globalColors.primary,
    width: '100%',
    padding: 15,
    borderRadius: 10,
    marginTop: 15,
  },

  signOutContainer: {
    position: 'absolute',
    bottom: 50,
  }

});