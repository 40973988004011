import React, {useCallback, useMemo} from 'react'
import {useDropzone} from 'react-dropzone'
import { MaterialIcons } from '@expo/vector-icons'; 

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};



export default function UploadFileWeb({ fileToUpload, setFileToUpload, parseUpload }: 
  { fileToUpload:any ,setFileToUpload:Function, parseUpload:Function }) {

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setFileToUpload(acceptedFiles);
    }
  }, [])

  const removeFile = () => {
    setFileToUpload(undefined)
  }
  const uploadFile = () => {
    parseUpload();
  }

  const { acceptedFiles, fileRejections, isDragActive, isFocused, isDragAccept, isDragReject, 
    getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 
      'image/jpeg': ['.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf'],
      'text/csv': ['.csv']
    }
  });
  const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    outline: 'none',
  };
  

  let visibleComponent = <p>loading</p>;

  if (fileToUpload === undefined) {
    // no files have been accepted 
    visibleComponent = 
    <div {...getRootProps()} style={baseStyle}>
      <input {...getInputProps()} />
      {
        isDragActive ? 
        <p>Drop your file here!</p> :
        <p>Drag and drop your files here, or click to select files</p> 
      }
      {
        fileRejections.length > 0 ? 
        <p>please use one of the following file types: .pdf, .jpg, .jpeg, .png, .csv</p>:
        <a></a>
      }
    </div>  
  } else {
    // we have an accepted file in dropzone
    visibleComponent = 
    <div>
      <p style={{fontWeight: 'bold'}}>{acceptedFiles[0]['name']}</p>
      <div onClick={removeFile}>
        <a>delete file</a>
      </div>
      <div onClick={uploadFile}>
        <a>upload file</a>
      </div>
    </div>
  }

  return (
    <section className="container">
      {visibleComponent}
    </section>

  )





}