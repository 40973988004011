import { Button, TouchableOpacity, View, TextInput  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import ContentContainer from '../../components/ContentContainer';
import StyledText from '../../components/StyledText';
import TextFieldFormik from '../../components/TextFieldFormik';
import DateFieldFormik from '../../components/DateFieldFormik';
import BooleanField from '../../components/BooleanFieldFormik';
import axiosInstance from '../../axiosInstance';
import {globalStyle} from '../../style';
import { replaceEmptyWithNull } from '../../utils/index'; 
import { handleFilingIdParam, navigateFiling } from '../../utils';
import ContentHeader from '../../components/ContentHeader';

export default function AddSpouseScreen({route, navigation}) {
  let initialValues = {
    relationship_id: '',
    firstName: '', 
    middleInitial: '', 
    lastName: '', 
    dob: '',
    ssn: '',
    disabledBool: '',
    studentBool: ''
  }
  const [ formValues, setFormValues ] = useState(initialValues);
  const [ filingId, setFilingId ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');

  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    axiosInstance
      .post('loadEditSpouseScreen', JSON.stringify({'filingId': newFilingId}), { headers: {'Content-Type': 'application/json'}, withCredentials: true})
      .then(function (response) {
        setFormValues(prevValues => ({
          ...prevValues,
          ...Object.fromEntries(Object.entries(response['data']).filter(([key]) => key in prevValues))
        }));
      })
      .catch(function (error) {console.log(error);});
  }, []);


  const continuePressed = (formValues, setSubmitting, setValues) => {
    let apiURL = '/addRelationship';
    let relationshipID = formValues['relationship_id'];
    let dependentDetails = replaceEmptyWithNull(structuredClone(formValues), ['dob', 'disabled_bool', 'student_bool']);
    dependentDetails['filingId'] = filingId;

    // if we are editing an existing dependent, then the query needs to change
    if (relationshipID !== '') {
      apiURL = '/editRelationship';
      dependentDetails['id'] = relationshipID
    };

    axiosInstance
      .post(apiURL, JSON.stringify(dependentDetails), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        navigateFiling(navigation, 'contactInfo', { filingId: filingId });
      })
      .catch(function (error) {console.log(error.message);})
  };

  const backPressed = (setValues) => {
    navigateFiling(navigation, 'personalInfo', { filingId: filingId });
  };

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (

      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed}>
        <ContentHeader icon={"group"} 
          headerText={"Share details on your spouse"} 
          subHeaderText="Filing together with your spouse can sometimes reduce your tax bill"/>

        <View style={globalStyle.fieldRow}>
          <TextFieldFormik name="firstName" fieldName="First Name" placeholder="First name" width={200} />  
          <TextFieldFormik name="middleInitial" fieldName="Middle Initial" placeholder="M.I." width={100} /> 
          <TextFieldFormik name="lastName" fieldName="Last Name" placeholder="Last name" width={250} /> 
        </View>
        <View style={globalStyle.fieldRow}>
          <DateFieldFormik name="dob" fieldName="Date Of Birth" placeholder="First name" width={200} /> 
          <TextFieldFormik name="ssn" fieldName="Social Security Number" placeholder="###-###-####" width={200} />  
        </View>
        <View style={globalStyle.fieldRow}>
          <BooleanField name="disabledBool" fieldName={"Is this person considered disabled?"} trueLabel={'Yes'} falseLabel={'No'}/>
        </View>
        <View style={globalStyle.fieldRow}>
          <BooleanField name="studentBool" fieldName={"Is this person a full time student?"} trueLabel={'Yes'} falseLabel={'No'}/>
        </View>
      </ContentContainer>
    )}
  </Formik>
  )
}