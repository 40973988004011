import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Animated, View } from 'react-native';
import { Formik } from 'formik';
import {globalStyle} from '../../style';
import ContentContainer from '../../components/ContentContainer';
import StyledText from '../../components/StyledText';
import TextFieldFormik from '../../components/TextFieldFormik';
import DateFieldFormik from '../../components/DateFieldFormik';
import DropdownField from '../../components/DropdownFieldFormik';
import BooleanField from '../../components/BooleanFieldFormik';
import { handleFilingIdParam, navigateFiling, replaceEmptyWithNull } from '../../utils';
import { filingStatuses } from '../../assets/constants';
import axiosInstance from '../../axiosInstance';
import {personalInfoSchema} from '../../assets/validations';
import ContentHeader from '../../components/ContentHeader';
import LottieAnimation from '../../components/LottieAnimation';
import animationData from '../../assets/animations/personalInfo.json'

 export default function PersonalInfoScreen({route, navigation}) {
  const initialValues = { 
    firstName: '', 
    middleInitial: '', 
    lastName: '', 
    dob: '', 
    ssn: '',
    filingStatus: '',
    occupation: '',
    dependentBool: ''}

  const [ formValues, setFormValues ] = useState(initialValues);
  const [ filingId, setFilingId ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');

  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    console.log(newFilingId);
    axiosInstance
    .post('loadPersonalInfoScreen', JSON.stringify({'filingId': newFilingId}), { headers: {'Content-Type': 'application/json'}, withCredentials: true})
    .then(function (response) {
      const formattedResponseData = { ...response.data };
      if (formattedResponseData.ssn) {formattedResponseData.ssn = formatSSN(formattedResponseData.ssn);}

      setFormValues(prevValues => ({
        ...prevValues,
        ...Object.fromEntries(Object.entries(formattedResponseData).filter(([key]) => key in prevValues))
      }));
    })
    .catch(function (error) {
    });
  }, []);

  const formatSSN = (value) => {
    const ssn = value.replace(/\D/g, ''); // Remove non-digit characters
    let formattedSSN = '';
  
    if (ssn.length <= 3) {
      formattedSSN = ssn;
    } else if (ssn.length <= 5) {
      formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    } else {
      formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
    }
  
    return formattedSSN;
  };

  const customFormatSSN = (text, setFieldValue) => {
    const formattedText = formatSSN(text); // Assuming formatSSN returns the formatted SSN
    setFieldValue('ssn', formattedText); // Directly update the 'ssn' field in Formik's state
  };

  const continuePressed = (formValues, setSubmitting, setValues) => {
    const processedValues = {
      ...formValues,
      ssn: formValues.ssn.replace(/-/g, ''),
    };
    let cleanFormValues = replaceEmptyWithNull(structuredClone(processedValues), ['dob', 'filingStatus', 'dependentBool']);
    cleanFormValues['filingId'] = filingId;
    axiosInstance
      .post('updatePersonalInfo', JSON.stringify(cleanFormValues), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        setSubmitting(false); 
        navigateFiling(navigation, response.data.next_screen, { filingId: filingId });
      })
      .catch(function (error) {
      });
  }
const backPressed = (setValues) => {
}
  
  const fadeAnim = useRef(new Animated.Value(1)).current; // Opacity animation for the into animation
  const slideAnim = useRef(new Animated.Value(500)).current; // Translation animation for the form
  const fadeInAnim = useRef(new Animated.Value(0)).current; // Start with an opacity of 0

  useEffect(() => {
    // Start by showing the first view
    const timer = setTimeout(() => {
      // After 3 seconds start fading out the first view
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: true
      }).start();

      // 1 second after fading starts, begin to slide up and fade in the second view
      setTimeout(() => {
        Animated.parallel([
          Animated.timing(slideAnim, {
            toValue: 0,
            duration: 500,
            useNativeDriver: true
          }),
          Animated.timing(fadeInAnim, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true
          })
        ]).start();
      }, 1000);
    }, 1200);
    return () => clearTimeout(timer);
  }, [fadeAnim, slideAnim]);

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      validationSchema={personalInfoSchema}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting, setFieldValue }) => (
      
      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={true} backTitle=''>
        <ContentHeader icon={"badge"} headerText={"Let's start by getting to know you"} />

        <View style={{width: '100%'}}>
          <Animated.View style={[styles.fadeView, { opacity: fadeAnim }]}>
            <LottieAnimation
              source={animationData}
              loop={true}
              autoplay={true}
              height={500}
              width={500}
            />
          </Animated.View>
          <Animated.View style={[styles.slideView, { transform: [{ translateY: slideAnim }], opacity: fadeInAnim }]}>
            <View style={globalStyle.fieldRow}>      
              <TextFieldFormik name="firstName" fieldName="First Name" placeholder="First name" fieldStyle={{'flex': 5, minWidth: 200}} />  
              <TextFieldFormik name="middleInitial" fieldName="M.I." placeholder="M. I." fieldStyle={{'flex': 2, minWidth: 100}} />  
              <TextFieldFormik name="lastName" fieldName="Last Name" placeholder="Last name" fieldStyle={{'flex': 5, minWidth: 200}} />        
            </View>

            <View style={globalStyle.fieldRow}>      
              <DateFieldFormik name="dob" fieldName="Date Of Birth" placeholder="First name" fieldStyle={{'flex': 2, minWidth: 200}} /> 
              <TextFieldFormik name="ssn" fieldName="Social Security Number" placeholder="###-###-####" fieldStyle={{'flex': 2, minWidth: 200}} keyboardType='numeric' onChangeText={customFormatSSN}/>         
            </View>

            <View style={globalStyle.fieldRow}>
              <DropdownField name="filingStatus" fieldName="Filing Status" placeholder="Choose" items={filingStatuses} fieldStyle={{'flex': 2, minWidth: 250}} />
              <TextFieldFormik name="occupation" fieldName="Occupation" placeholder="Occupation" fieldStyle={{'flex': 2, minWidth: 250}} />
            </View>

            <View style={globalStyle.fieldRow}>
              <BooleanField name="dependentBool" fieldName={"Can someone claim you as a dependent on their tax return?"} />
            </View>

            <View style={globalStyle.fieldRow}>
              <StyledText style={{}}>{errorMessage}</StyledText>
            </View>
          </Animated.View>
        </View>
      </ContentContainer>
     )}
    </Formik>
  )
 };

 const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fadeView: {

  },
  slideView: {
    position: 'absolute',
    width: '100%',
    height: 700,
    alignItems: 'center',

  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  }
});
