import { StyleSheet, Button, TouchableOpacity, View, TextInput  } from 'react-native';
import React from 'react';
import StyledText from '../../components/StyledText';
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import ContentContainer from '../../components/ContentContainer';
import ContentHeader from '../../components/ContentHeader';
import {globalStyle} from '../../style';
import { navigateFiling } from '../../utils/index';

export default function QualifiedDependentResultScreen({route, navigation}) {
  const firstName = route.params.firstName;
  const qualifiedBool = route.params.qualifiedBool;
  const dependentID = route.params.dependentID;
  const relationship = route.params.relationship;
  const isFocused = useIsFocused();

  const continuePressed = () => {
    navigateFiling(navigation, 'dependents', { filingId: route.params.filingId });
  };
  const backPressed = () => {
    navigateFiling(navigation, relationship, {'filingId': route.params.filingId, 'dependentID': dependentID});
  };

  const qualifiedText = (qualifiedBool) => {
    return (qualifiedBool=== true) ? `Yes, ${firstName} is a qualified dependent.`: `No, ${firstName} is not a qualified dependent.`;
  }

  return (

    <ContentContainer nextPress={continuePressed} nextDisabled={false} backPress={backPressed}>
      <ContentHeader icon={"family-restroom"} headerText={`${qualifiedText(qualifiedBool)}`}/>
    </ContentContainer>

  );
}