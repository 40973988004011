import React, {useState} from 'react';
import { Pressable, Text, StyleSheet, View } from 'react-native';
import { globalColors } from '../style';
import StyledText from './StyledText';

const StyledCard = ({ firstLine = '', secondLine='', rightContainer, onPress, style = {}, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Pressable 
      style={[styles.card, style, isHovered && styles.hoveredCard]} 
      onPress={onPress} 
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      {...props}>
      <View style={styles.leftContainer}>
        <StyledText style={[styles.headerText, isHovered && {color: globalColors.primary}]}>{firstLine}</StyledText>
        <StyledText style={styles.subHeaderText}>{secondLine}</StyledText>
      </View>
      <View style={styles.rightContainer}>
        {rightContainer}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  card: {
    flex: 1,
    flexDirection: 'row',
    padding: 15,
    marginVertical: 1,
    borderRadius: 15,
    borderColor: globalColors.grey,
    borderWidth: 1,
    backgroundColor: 'white',
    width: '100%', // Add this line to make it span the whole width
  },
  hoveredCard: {
    borderColor: globalColors.primary, 
    borderWidth: 2,
    marginVertical: 0
  },
  leftContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  rightContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  subHeaderText: {

  }
  });

export default StyledCard;
