import 'react-native-gesture-handler';
import React from 'react';
import { useWindowDimensions, Animated, View } from 'react-native';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { setNavigatorRef } from './axiosInstance';
import { globalColors } from './style.js';
import DrawerContainer from './components/DrawerContainer';
import { largeScreen, drawerWidth } from './assets/constants';
import Logo from './components/Logo';
import StyledButton from './components/StyledButton';
import StyledText from './components/StyledText';

// Landing Screens
import HomeScreen from './screens/landing/HomeScreen';
import LoginScreen from './screens/landing/LoginScreen';
import SignUpScreen from './screens/landing/SignUpScreen';
import FilingScreen from './screens/landing/FilingScreen';

// Personal Info Screens
import PersonalInfoScreen from './screens/personal/PersonalInfoScreen';
import AddSpouseScreen from './screens/personal/AddSpouseScreen';
import ContactInfoScreen from './screens/personal/ContactInfoScreen';
import ResidencyScreen from './screens/personal/ResidencyScreen';
import DependentsScreen from './screens/personal/DependentsScreen';
import AddDependentScreen from './screens/personal/AddDependentScreen';
import QualifiedChildScreen from './screens/personal/QualifiedChildScreen';
import QualifiedRelativeScreen from './screens/personal/QualifiedRelativeScreen';
import QualifiedDependentResultScreen from './screens/personal/QualifiedDependentResultScreen';

// Income screens
import IncomeSourcesScreen from './screens/federal/income/IncomeSourcesScreen';
import W2Screen from './screens/federal/income/W2Screen';
import AddW2Screen from './screens/federal/income/AddW2Screen';
import F1099IntScreen from './screens/federal/income/F1099IntScreen';
import AddF1099IntScreen from './screens/federal/income/AddF1099IntScreen';
import F1099DivScreen from './screens/federal/income/F1099DivScreen';
import AddF1099DivScreen from './screens/federal/income/AddF1099DivScreen';
import F1099BScreen from './screens/federal/income/F1099BScreen';
import AddF1099BScreen from './screens/federal/income/AddF1099BScreen';
import AddF1099BItemScreen from './screens/federal/income/AddF1099BItemScreen';

// Deduction screens
import DeductionIntroScreen from './screens/federal/deductions/DeductionIntroScreen';
import DeductionScreen from './screens/federal/deductions/DeductionScreen';
import MedicalDeductionScreen from './screens/federal/deductions/MedicalDeductionScreen';

// Other screens
import OtherTaxFormsScreen from './screens/federal/other/OtherSituationsScreen';
import ForeignAccountScreen from './screens/federal/income/ForeignAccountScreen';
import ForeignAccountSummaryScreen from './screens/federal/income/ForeignAccountSummaryScreen';
import AddForeignAccount from './screens/federal/income/AddForeignAccount';
import HSAAccountScreen from './screens/federal/other/HSAAccountScreen';
import HSAContributionsScreen from './screens/federal/other/HSAContributions';
import HSADistributionsScreen from './screens/federal/other/HSADistributions';

// Extension screens
import ExtensionPaymentScreen from './screens/federal/other/ExtensionPaymentScreen';

import BankAccountScreen from './screens/review/BankAccountScreen';
import GenerateFilingScreen from './screens/review/GenerateFilingScreen';

// State screens
import NYIntroScreen from './screens/state/NYIntroScreen';

import { REACT_APP_FRONTEND_URL } from '@env';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const forSlideAndFade = ({ current, next, inverted, layouts: { screen } }) => {
  const progress = Animated.add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    next
      ? next.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
          extrapolate: 'clamp',
        })
      : 0
  );

  return {
    cardStyle: {
      opacity: progress.interpolate({
        inputRange: [0, 0.5, 1],
        outputRange: [0, 1, 1],
      }),
      transform: [
        {
          translateY: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [50, 0],
          }),
        },
      ],
    },
  };
};

const forFade = ({ current }) => ({
  cardStyle: {
    opacity: current.progress,
  },
});

const FilingStack = createStackNavigator();
function FilingStackNavigator() {
  return (
    <FilingStack.Navigator       
    screenOptions={{
      headerShown: false,
      gestureEnabled: true,
      cardStyleInterpolator: forSlideAndFade
    }}>
      <FilingStack.Screen name="personalInfo" component={PersonalInfoScreen} />
      <FilingStack.Screen name="addSpouse" component={AddSpouseScreen} />

      <FilingStack.Screen name="contactInfo" component={ContactInfoScreen} />
      <FilingStack.Screen name="residency" component={ResidencyScreen} />
      
      <FilingStack.Screen name="dependents" component={DependentsScreen} />
      <FilingStack.Screen name="addDependent" component={AddDependentScreen} />
      <FilingStack.Screen name="qualifiedChild" component={QualifiedChildScreen} />
      <FilingStack.Screen name="qualifiedRelative" component={QualifiedRelativeScreen} />
      <FilingStack.Screen name="qualifiedDependentResult" component={QualifiedDependentResultScreen} />

      <FilingStack.Screen name="incomeSources" component={IncomeSourcesScreen} />
      <FilingStack.Screen name="w2" component={W2Screen} />
      <FilingStack.Screen name="addW2" component={AddW2Screen} />
      <FilingStack.Screen name="f1099Int" component={F1099IntScreen} />
      <FilingStack.Screen name="addF1099Int" component={AddF1099IntScreen} />
      <FilingStack.Screen name="f1099Div" component={F1099DivScreen} />
      <FilingStack.Screen name="addF1099Div" component={AddF1099DivScreen} />
      <FilingStack.Screen name="f1099B" component={F1099BScreen} />
      <FilingStack.Screen name="addF1099B" component={AddF1099BScreen} />
      <FilingStack.Screen name="addF1099BItem" component={AddF1099BItemScreen} />

      <FilingStack.Screen name="deductionsIntro" component={DeductionIntroScreen} />
      <FilingStack.Screen name="deduction" component={DeductionScreen} />
      
      <FilingStack.Screen name="otherSituations" component={OtherTaxFormsScreen} />
      <FilingStack.Screen name="foreignAccount" component={ForeignAccountScreen} />
      <FilingStack.Screen name="foreignAccountSummary" component={ForeignAccountSummaryScreen} />
      <FilingStack.Screen name="addForeignAccount" component={AddForeignAccount} />

      <FilingStack.Screen name="hsaAccount" component={HSAAccountScreen} />
      <FilingStack.Screen name="hsaContributions" component={HSAContributionsScreen} />
      <FilingStack.Screen name="hsaDistributions" component={HSADistributionsScreen} />

      <FilingStack.Screen name="extensionPayment" component={ExtensionPaymentScreen} />
      
      <FilingStack.Screen name="nyIntro" component={NYIntroScreen} />

      <FilingStack.Screen name="bankAccount" component={BankAccountScreen} />
      <FilingStack.Screen name="generateFiling" component={GenerateFilingScreen} />
    </FilingStack.Navigator>
  );
}

function DrawerNavigator() {
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= largeScreen;
  return (
    <Drawer.Navigator 
      drawerContent={props => <DrawerContainer {...props} />} 
      defaultStatus="closed"
      screenOptions={{
        drawerType: isLargeScreen ? 'permanent' : 'front',
        drawerStyle: { 
          width: isLargeScreen ? drawerWidth : '100%' 
        },
        overlayColor: 'transparent',
        headerShown: !isLargeScreen,
      }}
      initialRouteName='personalInfo'
      backBehavior="history">
      <Drawer.Screen name="filingStack" component={FilingStackNavigator} options={{ title: 'Personal Info' }} />
    </Drawer.Navigator>
  );
}

function LandingNavigator() {
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= largeScreen;
  return (
    <Stack.Navigator 
      screenOptions={{
        cardStyleInterpolator: forFade, 
        headerStyle: isLargeScreen ?  {backgroundColor: '#fff', height: 80} : {backgroundColor: '#fff', height: 72},
        headerTitleAlign: 'left', 
        drawerType: isLargeScreen ? 'permanent' : 'back',
        drawerStyle: {width: isLargeScreen ? drawerWidth : '100%'},
        headerLeft: () => null,
        headerTitle: () => <Logo style={{marginLeft: isLargeScreen ? 48 : 12}}/>}}>

      <Stack.Screen name="home" component={HomeScreen} options={{headerLeft: () => null }}/>
      <Stack.Screen name="login" component={LoginScreen} />
      <Stack.Screen name="signup" component={SignUpScreen} />
      <Stack.Screen name="filings" component={FilingScreen} />
    </Stack.Navigator>
  );
}

export default function App() {
  const linking = { prefixes: [REACT_APP_FRONTEND_URL] }; 
  return (
    <SafeAreaProvider>
      <NavigationContainer linking={linking} ref={(ref) => {setNavigatorRef(ref);}}>
        <Stack.Navigator initialRouteName="home">
          <Stack.Screen name="landing" component={LandingNavigator} options={{ headerShown: true, headerStyle:{height: 1} }}/>
          <Stack.Screen name="filing" component={DrawerNavigator} options={{ headerShown: false }}/>
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
  );
}