import { StyleSheet, Button, TouchableOpacity, View, TextInput  } from 'react-native';
import { useIsFocused } from '@react-navigation/native'
import React, { useState, useEffect } from 'react';
import { Formik,useFormikContext } from 'formik';
import ContentContainer from '../../components/ContentContainer';
import StyledText from '../../components/StyledText';
import StyledCard from '../../components/StyledCard';
import BooleanField from '../../components/BooleanFieldFormik';
import axiosInstance from '../../axiosInstance';
import {globalColors, globalStyle} from '../../style';
import { MaterialIcons } from '@expo/vector-icons'; 
import { handleFilingIdParam, navigateFiling } from '../../utils/index';
import ContentHeader from '../../components/ContentHeader';

export default function DependentsScreen({navigation, route}) {
  const initialValues = {
    dependentsBool: ''
  }
  const [dependentsBool, setDependentsBool] = useState<string | boolean>('')
  const [dependents, setDependents] = useState([])
  const [formValues, setFormValues] = useState<any>(initialValues);
  const [filingId, setFilingId] = useState('');
  const isFocused = useIsFocused()

  // re-render screen every time its opened
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    if (isFocused) {
      pullDependents(newFilingId)
    }
  } , [isFocused]);

  useEffect(() => {
    // when user select no to dependents, empty dependents
    if (dependentsBool===false) {
      axiosInstance
        .post('deleteAllDependents', JSON.stringify({'filingId': filingId}), { headers: {'Content-Type': 'application/json'}, withCredentials: true})
        .then(function (response) {setDependents([]);})
        .catch(function (error) {console.log(error)});
    } 
  }, [dependentsBool]); 

  const pullDependents = (newFilingId) => {
    axiosInstance
      .post('loadDependentsScreen', JSON.stringify({'filingId': newFilingId}), { headers: {'Content-Type': 'application/json'}, withCredentials: true})
      .then(function (response) {
        const rs = response['data'];
        setDependents(rs);
        setFormValues({dependentsBool: rs.length > 0 ? true: false})
      })
      .catch(function (error) {console.log(error)});
  };
  const addDependent = () => {
    navigateFiling(navigation, 'addDependent', {'filingId': filingId, dependentID: null});
  };

  const removeDependent = (dependentID) => {
    var jsonData = JSON.stringify({'dependentId': dependentID, 'filingId': filingId});
    axiosInstance
      .post('deleteRelationship', jsonData, { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {setDependents(response['data']);})
      .catch(function (error) {console.log(error.message);})
  };

  const continuePressed = (values, setSubmitting, setValues) => {
    setSubmitting(false);
    navigateFiling(navigation, 'incomeSources', { filingId: filingId });
  };
  const backPressed = (setValues) => {
    navigateFiling(navigation, 'contactInfo', { filingId: filingId });
  };
  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues}) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      
    <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed}>
      <ContentHeader icon={"family-restroom"} headerText={"Tell us about any dependents"} subHeaderText="These relationships could reduce your tax bill"/>
      
      <View style={styles.dependentContainer}>
        <View style={globalStyle.fieldRow}>
          <BooleanField name='dependentsBool' fieldName={"Did you have any dependents in 2021?"}/>
        </View>
        <View style={{width: '100%', flexDirection: 'row', columnGap: 20, flexWrap: 'wrap'}}>
          {// create a row for each dependent in the state
            dependents.map((dependent, index) => (
              <View style={{...globalStyle.fieldRow, flex: 1, minWidth: 300, maxWidth: 420}} key={index}>
                <StyledCard 
                  firstLine={dependent.firstName + ' ' + dependent.lastName} 
                  secondLine={dependent.firstName} 
                  rightContainer={
                    <TouchableOpacity onPress={()=>removeDependent(dependent.id)}>
                      <MaterialIcons name="delete-outline" size={24} color={globalColors.negative} />
                    </TouchableOpacity>
                  }
                  onPress={()=> navigateFiling(navigation, 'addDependent', {'filingId': filingId, dependentID: dependent.id}) }/>
              </View>
            ))
          }
          {
            <View style={{...globalStyle.fieldRow, flex: 1, minWidth: 300, maxWidth: 420}} >
              {values['dependentsBool'] ? 
                <StyledCard 
                  firstLine='Add Dependent' 
                  rightContainer={<StyledText>+</StyledText>}
                  onPress={addDependent}/>
                : <View></View>}
            </View>
          }
        </View>
      </View>
    </ContentContainer>
  )}
  </Formik>
  );
}
const styles = StyleSheet.create({
  dependentContainer: {
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'left', // Add this line
    alignItems: 'left', // Add this line
  },
});
