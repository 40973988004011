import { StyleSheet, Button, TouchableOpacity, View, TextInput  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../components/ContentContainer';
import TextFieldFormik from '../../components/TextFieldFormik';
import DateFieldFormik from '../../components/DateFieldFormik';
import ContentHeader from '../../components/ContentHeader';
import DropdownField from '../../components/DropdownFieldFormik';
import axiosInstance from '../../axiosInstance';
import {globalStyle} from '../../style';
import { replaceNullWithEmpty, replaceEmptyWithNull, updateWithDictOverlap, navigateFiling } from '../../utils/index';
import { handleFilingIdParam } from '../../utils';
import { relationshipTypes } from '../../assets/constants';

export default function AddDependentScreen({route, navigation}) {
  const initialValues = {
    'firstName': '', 
    'middleInitial': '', 
    'lastName': '', 
    'dob': '',
    'ssn': '',
    'type': '',
  }
  const [ formValues, setFormValues ] = useState(initialValues);
  const [filingId, setFilingId] = useState('');
  const isFocused = useIsFocused();

  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    setFormValues(initialValues);
    let dependentID = route.params.dependentID;
    if (isFocused && dependentID !== null) {
      pullDependent(newFilingId, dependentID);
    }
  }, [isFocused]);

  const pullDependent = (newFilingId, dependentID) => {
    var jsonData = JSON.stringify({'relationshipId': dependentID, 'filingId': newFilingId});
    axiosInstance
      .post('loadEditDependentScreen', jsonData, { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        let newVals = replaceNullWithEmpty(response['data']);
        setFormValues(prevValues => updateWithDictOverlap(prevValues, newVals));
      })
      .catch(function (error) {console.log(error);});
  };

  const continuePressed = (formValues, setSubmitting, setValues) => {
    let cleanFormValues = replaceEmptyWithNull(structuredClone(formValues), ['dob']);
    cleanFormValues['filingId'] = filingId;
    let apiURL = '/addRelationship';
    const dependentID = route.params.dependentID;
    // if we are editing an existing dependent, then the query needs to change
    if (dependentID !== null) {
      apiURL ='/editRelationship';
      cleanFormValues['id'] = dependentID
    };
    axiosInstance
      .post(apiURL, JSON.stringify(cleanFormValues), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
          setSubmitting(false);
          if (response['data']['next_screen'] === 'qualifiedDependentResult') {
            navigateFiling(navigation, 'addF1099Int', 
              {'firstName': formValues['firstName'], 
              'qualifiedBool': false,
              'dependentID': route.params.dependentID,
              'relationship': 'addDependent'});
          } else {
            navigateFiling(navigation, response.data.next_screen, {'dependentID': response['data']['dependent_id'], 'filingId': filingId});
          }
        })
      .catch(function (error) {console.log(error.message);})
  };
  const backPressed = (setValues) => {
    navigateFiling(navigation, 'dependents', { filingId: filingId });
  };

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
    <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed}>
      <ContentHeader icon={"family-restroom"} headerText="Share details on your dependent"/>
  
      <View style={globalStyle.fieldRow}>
        <TextFieldFormik name="firstName" fieldName="First Name" placeholder="First name" fieldStyle={{'flex': 5, minWidth: 200}}  />  
        <TextFieldFormik name="middleInitial" fieldName="Middle Initial" placeholder="M.I." fieldStyle={{'flex': 2, minWidth: 100}}/> 
        <TextFieldFormik name="lastName" fieldName="Last Name" placeholder="Last name" fieldStyle={{'flex': 5, minWidth: 200}}/> 
      </View>
      <View style={globalStyle.fieldRow}>
        <DateFieldFormik name="dob" fieldName="Date Of Birth" placeholder="First name" fieldStyle={{'flex': 2, minWidth: 200}} /> 
        <TextFieldFormik name="ssn" fieldName="Social Security Number" placeholder="###-###-####" fieldStyle={{'flex': 2, minWidth: 200}}  />   
      </View>
      <View style={globalStyle.fieldRow}>
        <DropdownField name="type" fieldName="Relationship" placeholder="Choose" items={relationshipTypes} width={200}/>
      </View>

    </ContentContainer>
     )}
     </Formik>
  );
}