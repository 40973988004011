import {  View  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import DollarFieldFormik from '../../../components/DollarFieldFormik';
import StyledText from '../../../components/StyledText';
import BooleanField from '../../../components/BooleanFieldFormik';
import {globalStyle} from '../../../style';
import { handleFilingIdParam, handleFormIdParam, editFormValues, navigateFiling, pullFormValues, prettifyNumber } from '../../../utils';

export default function HSAContributionsScreen({route, navigation}) {
  const initialValues = {
    'otherEmployerContributions': '',
    'employerContributionsForPrevious': '',
    'employerContributionsCurrent': '',
    'employerContributionsFromNext': '',
    'nonEmployerContributions': ''
  }

  const [formValues, setFormValues] = useState(initialValues);
  const [w2Contributions, setW2Contributions] = useState(0); // this is the total employer contributions from the W2 form
  const [filingId, setFilingId] = useState('');
  const [formId, setFormId] = useState('');
  const isFocused = useIsFocused();
  const numericFields = ['employerContributionsForPrevious', 'employerContributionsCurrent', 'employerContributionsFromNext', 'nonEmployerContributions']

  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, numericFields); 

    // we also grab total employer contributions from the W2 form
    let totalEmployerContributions = 0;
    pullFormValues(newFilingId, 'W2').then(response => {
      console.log(response);

      // sum up all of the employer contributions from the W2 form
      for (let i = 0; i < response['data'].length; i++) {
        const data = response['data'][i];
        Object.keys(data).forEach(key => {
          if (key.indexOf("12Description") === 0 && data[key] === "W") {
            totalEmployerContributions += data[key.replace("Description", "Amount")];
          }
        });
      }
      setW2Contributions(totalEmployerContributions);
    });

  }, [isFocused]);

 
  const continuePressed = (formValues, setSubmitting, setValues) => {
    editFormValues(setSubmitting, setValues, filingId, formId, 'Medical Input', formValues, initialValues, navigation, 'hsaDistributions', numericFields)
  };

  const backPressed = (setValues) => {
    navigateFiling(navigation, 'hsaAccount', { filingId: filingId });
  };

  return (
    <Formik 
    initialValues={formValues} 
    enableReinitialize={true}
    onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
    {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
        <ContentHeader icon={"medical-services"} headerText="Tell us about your HSA contributions"/>
 
        <View style={{marginTop: 20}}>
          <StyledText style={globalStyle.sectionHeaderText}>Contributions through your employer</StyledText>
          <View style={{'flexDirection': 'row'}}>
            <StyledText style={globalStyle.fieldName}>Total employer contributions from your W2 forms: </StyledText>
            <StyledText style={{'fontWeight': 'bold'}}>${prettifyNumber(w2Contributions)}</StyledText>
          </View>

        </View>
        <View style={globalStyle.fieldRow}>
          <BooleanField name="otherEmployerContributions" fieldName="It's uncommon, but did your employer tell you of any other contributions besides what is stated on your W2 (above)?" />
        </View>
        {values['otherEmployerContributions'] === true ?
          <View>
            <DollarFieldFormik name="employerContributionsForPrevious" fieldName="Employer contributions made in 2023 for tax year 2022?" placeholder="" textStyle={{'maxWidth': 200}} /> 
            <DollarFieldFormik name="employerContributionsCurrent" fieldName="Employer contributions made in 2023 for tax year 2023, but NOT included in your W2?" placeholder="" textStyle={{'maxWidth': 200}}  /> 
            <DollarFieldFormik name="employerContributionsFromNext" fieldName="Employer contributions made in 2024 for tax year 2023?" placeholder="" textStyle={{'maxWidth': 200}}  /> 
          </View> : null}

          <View style={globalStyle.dividerLine} />
          <StyledText style={globalStyle.sectionHeaderText}>Contributions not through your employer</StyledText>
          <DollarFieldFormik name="nonEmployerContributions" fieldName="How much was contributed to your HSA, not through an Employer?" placeholder="" textStyle={{'maxWidth': 200}}  /> 

      </ContentContainer>
    )}
    </Formik>
  );
}