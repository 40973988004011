import { StyleSheet, Button, TouchableOpacity, Text, View, TextInput  } from 'react-native';
import { useIsFocused } from '@react-navigation/native'
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';
import StyledText from '../../../components/StyledText';  
import StyledButton from '../../../components/StyledButton';
import ContentContainer from '../../../components/ContentContainer';
import {globalStyle} from '../../../style';
import { MaterialIcons } from '@expo/vector-icons'; 
import { handleFilingIdParam, navigateFiling } from '../../../utils';

export default function F1099DivScreen({navigation, route}) {
  // declare field state variables
  const [f1099s, setF1099s] = useState([])
  const [ filingId, setFilingId ] = useState('');
  const isFocused = useIsFocused()

  // re-render screen every time its opened
  useEffect(() => {
    if (isFocused) {
      const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
      pullF1099Divs(newFilingId);
    }
  } , [isFocused]);

  const pullF1099Divs = (newFilingId) => {
    axiosInstance
      .post('loadForms', JSON.stringify({'formName': 'Form 1099-DIV', 'filingId': newFilingId}), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {setF1099s(response['data']);})
      .catch(function (error) {console.log(error)});
  };

  const deleteF1099Div = (userFormId) => {
    var jsonData = JSON.stringify({'userFormId': userFormId, 'filingId': filingId});
    axiosInstance
    .post('deleteUserForm', jsonData, { headers: {'Content-Type': 'application/json' }, withCredentials: true})
    .then(function (response) {pullF1099Divs(filingId);})
    .catch(function (error) {console.log(error.message);})
  };

  const continuePressed = () => {
    navigateFiling(navigation, 'incomeSources', { filingId: filingId });
  };
  const backPressed = () => {
    navigateFiling(navigation, 'incomeSources', { filingId: filingId });
  };

  return (
    <ContentContainer nextPress={continuePressed} nextDisabled={false} backPress={backPressed}>
      <StyledText style={globalStyle.screenHeaderText}>Your Dividend Income (Form 1099-Div)</StyledText>
      <StyledText style={globalStyle.screenSubHeaderText}>Enter any income earned through dividends.</StyledText>
      
      {
        f1099s.map((form, index) => (
          <View style={globalStyle.fieldRow} key={index}>
            <View>
              <StyledText style={[globalStyle.fieldNameStyle, {fontWeight: 'bold', paddingRight:10}]}>{form.payerName}</StyledText>
            </View>
            
            <TouchableOpacity onPress={() => navigateFiling(navigation, 'addF1099Div', { filingId: filingId, formId: form.id }) } >
              <MaterialIcons name="edit" size={24} color="black" />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>deleteF1099Div(form.id)}>
              <MaterialIcons name="delete-outline" size={24} color="black" />
            </TouchableOpacity>
          </View>
        ))
      }
      {
        <View style={globalStyle.fieldRow}>
          <StyledButton title='Add a F1099-Div form' onPress={()=>navigateFiling(navigation, 'addF1099Div', { filingId: filingId, formId: null })}/>
        </View>
      }
    </ContentContainer>
  );
}